import { dummyProfile } from '@assets/icons';
import Image from '@components/elements/Image';
import Section from '@components/elements/Section';
import Heading from '@components/elements/Text/Heading';
import Paragraph from '@components/elements/Text/Paragraph';

interface IDashCard {
  img?: string;
  title?: string;
  desc?: string;
}

const Dashcard = ({ img, title, desc }: IDashCard) => {
  return (
    <Section className="flex items-center shadow-sm py-4 px-2">
      <Section>
        <Image src={img || dummyProfile} alt={''} />
      </Section>
      <Section className="ml-5">
        <Heading className="text-base text-neutral-copy-black">{title || 'Crad Title'}</Heading>
        <Paragraph className="text-xs">{desc || 'Card Description'}</Paragraph>
      </Section>
    </Section>
  );
};

export default Dashcard;
