import HomePage from '@pages/index';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import appRoutes from './appRoutes';
import Signup from '@pages/signup';
import ProtectedRoute from './protectedRoute';
import { useAuth } from '@features/Auth/hooks/useAuthStore';
import PublicRoute from './publicRoute';
import Contact from '@pages/contact';
import AdminLogin from '@pages/admin-login';
import Students from '@pages/students';
import PoliceClearance from '@pages/police-clearance';
import StudentDetails from '@pages/students/student-details';
import Accounts from '@pages/accounts';
import Attestation from '@pages/attestation';
import Payments from '@pages/payments';
import Users from '@pages/users';
import Etcs from '@pages/etc';
import NotFound from '@pages/404';
import LostPassportModule from '@features/Passports/components/LostAndFound';
import ReturnedPassportModule from '@features/Passports/components/Returned';

const AppRoutes: React.FC<{}> = () => {
  const auth = useAuth();
  return (
    <Routes>
      <Route
        path={appRoutes.HOME}
        element={
          <ProtectedRoute isAllowed={auth !== null} redirectPath={'/signin'}>
            <HomePage />
          </ProtectedRoute>
        }
      />
      <Route
        path={appRoutes.POLICE_CLEARANCE}
        element={
          <ProtectedRoute isAllowed={auth !== null} redirectPath={'/signin'}>
            <PoliceClearance />
          </ProtectedRoute>
        }
      />
      <Route
        path={appRoutes.ACCOUNTS}
        element={
          <ProtectedRoute isAllowed={auth !== null} redirectPath={'/signin'}>
            <Accounts />
          </ProtectedRoute>
        }
      />
      <Route
        path={appRoutes.ATTESTATION_LETTER}
        element={
          <ProtectedRoute isAllowed={auth !== null} redirectPath={'/signin'}>
            <Attestation />
          </ProtectedRoute>
        }
      />
      <Route
        path={appRoutes.PAYMENTS}
        element={
          <ProtectedRoute isAllowed={auth !== null} redirectPath={'/signin'}>
            <Payments />
          </ProtectedRoute>
        }
      />
      <Route
        path={appRoutes.SIGNUP}
        element={
          <PublicRoute isAllowed={auth === null} redirectPath={'/'}>
            <Signup />
          </PublicRoute>
        }
      />
      <Route
        path={appRoutes.CONTACT}
        element={
          <ProtectedRoute isAllowed={auth !== null} redirectPath={'/signin'}>
            <Contact />
          </ProtectedRoute>
        }
      />
      <Route
        path={appRoutes.STUDENT}
        element={
          <ProtectedRoute isAllowed={auth !== null} redirectPath={'/signin'}>
            <Students />
          </ProtectedRoute>
        }
      />
      <Route
        path={appRoutes.STUDENT_DETAILS}
        element={
          <ProtectedRoute isAllowed={auth !== null} redirectPath={'/signin'}>
            <StudentDetails />
          </ProtectedRoute>
        }
      />
      <Route
        path={appRoutes.ADMIN_LOGIN}
        element={
          <PublicRoute isAllowed={auth === null} redirectPath={'/'}>
            <AdminLogin />
          </PublicRoute>
        }
      />
      <Route
        path={appRoutes.USERS}
        element={
          <ProtectedRoute isAllowed={auth !== null} redirectPath={'/signin'}>
            <Users />
          </ProtectedRoute>
        }
      />
      <Route
        path={appRoutes.ETC}
        element={
          <ProtectedRoute isAllowed={auth !== null} redirectPath={'/signin'}>
            <Etcs />
          </ProtectedRoute>
        }
      />
      <Route
        path={appRoutes.LOST_PASSPORTS}
        element={
          <ProtectedRoute isAllowed={auth !== null} redirectPath={'/signin'}>
            <LostPassportModule />
          </ProtectedRoute>
        }
      />
      <Route
        path={appRoutes.RETURNED_PASSPORTS}
        element={
          <ProtectedRoute isAllowed={auth !== null} redirectPath={'/signin'}>
            <ReturnedPassportModule />
          </ProtectedRoute>
        }
      />
      <Route element={<NotFound />} path="*" />
    </Routes>
  );
};

export default AppRoutes;
