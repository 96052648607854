import {
  accountsActive,
  accountsBase,
  callActive,
  callBase,
  close,
  dashboardActive,
  dashboardBase,
  etcActive,
  etcBase,
  logo,
  logout,
  pensionActive,
  pensionBase,
  policeActive,
  policeBase,
  studentActive,
  studentBase,
} from '@assets/icons';
import Image from '@components/elements/Image';
import SidebarMenuItem from '@components/elements/Menu/SidebarMenuItem';
import Section from '@components/elements/Section';
import Paragraph from '@components/elements/Text/Paragraph';
import appRoutes from '@components/router/appRoutes';
import { useAuthActions } from '@features/Auth/hooks/useAuthStore';

import { useLocation, useNavigate } from 'react-router-dom';

interface MobileSideNavProps {
  openMobileNav: boolean;
  setOpenMobileNav: () => void;
}

const MobileSideNav: React.FC<MobileSideNavProps> = ({
  openMobileNav,
  setOpenMobileNav,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setUser } = useAuthActions();

  return (
    <>
      <div
        className={`z-[999] flex flex-col justify-between fixed top-0 bottom-0 h-full bg-white px-4 py-8 w-80 sm:w-96 transition-all ease-in-out duration-300 overflow-y-auto ${
          openMobileNav ? 'left-0' : '-left-[32rem]'
        }`}
      >
        <button onClick={setOpenMobileNav} className="block w-fit ml-auto">
          <img src={close} alt="icon" width={20} height={20} />
        </button>

        <div>
          <img src={logo} alt="light logo" />
        </div>

        <aside className="mt-24 grid grid-cols-1 gap-2">
          <SidebarMenuItem
            title="Dashboard"
            active={
              location.pathname === '/' ||
              location.pathname.includes(appRoutes.CONSULAR_SERVICES)
            }
            url="/"
            activeIcon={dashboardActive}
            baseIcon={dashboardBase}
            onClick={setOpenMobileNav}
            as={'link'}
          />

          <SidebarMenuItem
            title="Students"
            active={
              location.pathname === appRoutes.STUDENT ||
              location.pathname.includes(appRoutes.STUDENT_DETAILS)
            }
            url={appRoutes.STUDENT}
            activeIcon={studentActive}
            baseIcon={studentBase}
            onClick={setOpenMobileNav}
            as={'link'}
          />

          <SidebarMenuItem
            title="Police"
            active={location.pathname === appRoutes.POLICE_CLEARANCE}
            url={appRoutes.POLICE_CLEARANCE}
            activeIcon={policeActive}
            baseIcon={policeBase}
            onClick={setOpenMobileNav}
            as={'link'}
          />

          <SidebarMenuItem
            title="Pensions"
            active={location.pathname === appRoutes.ATTESTATION_LETTER}
            url={appRoutes.ATTESTATION_LETTER}
            activeIcon={pensionActive}
            baseIcon={pensionBase}
            onClick={setOpenMobileNav}
            as={'link'}
          />

          <SidebarMenuItem
            title="Users"
            active={location.pathname === appRoutes.USERS}
            url={appRoutes.USERS}
            activeIcon={accountsActive}
            baseIcon={accountsBase}
            onClick={setOpenMobileNav}
            as={'link'}
          />

          <SidebarMenuItem
            title="Etcs"
            active={location.pathname === appRoutes.ETC}
            url={appRoutes.ETC}
            activeIcon={etcActive}
            baseIcon={etcBase}
            onClick={setOpenMobileNav}
            as={'link'}
          />

          <SidebarMenuItem
            title="Manage IVR Calls"
            active={location.pathname === appRoutes.MANAGE_CALLS}
            url={appRoutes.MANAGE_CALLS}
            activeIcon={callActive}
            baseIcon={callBase}
            as={'a'}
          />
        </aside>
        <Section
          className="mt-auto flex items-center cursor-pointer"
          onClick={() => {
            setUser(null);
            sessionStorage.clear();
            setOpenMobileNav();
            return navigate('/signin');
          }}
        >
          <Image
            src={logout}
            alt="logout | Nigerian High Commission London UK"
          />
          <Paragraph className="ml-2">Logout</Paragraph>
        </Section>
      </div>

      {/* overlay */}
      {openMobileNav && (
        <div
          onClick={setOpenMobileNav}
          className="h-full w-full z-20 bg-dark/50 fixed top-0 left-0 bottom-0"
        ></div>
      )}
    </>
  );
};

export default MobileSideNav;
