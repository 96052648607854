import Table from '@components/elements/Table';
import React, { SetStateAction } from 'react';
import { useUsersData } from './api';
import { createColumnHelper } from '@tanstack/react-table';
import Dropdown from '@components/elements/Dropdown';
import Section from '@components/elements/Section';
import { ellipsis } from '@assets/icons';
import { Link } from 'react-router-dom';
import Paragraph from '@components/elements/Text/Paragraph';
import StatusBadge from '@components/elements/StatusBadge';

const UsersModule = () => {
  const [USERS] = useUsersData();
  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor(() => 'num', {
      id: 'num',
      header: () => <span>S/N</span>,
      cell: (info) => {
        const value = info.row.index + 1;
        return <span>{value}</span>;
      },
    }),
    columnHelper.accessor((row: any) => 'firstname', {
      id: 'firstname',
      cell: (info: any) => {
        const { firstname } = info.row.original;
        return <span>{firstname || '-'}</span>;
      },
    }),
    columnHelper.accessor(() => 'lastname', {
      id: 'lastname',
      cell: (info: any) => {
        const { lastname } = info.row.original;
        return <span>{lastname || '-'}</span>;
      },
    }),
    columnHelper.accessor((row: any) => 'Student No', {
      id: 'Student No',
      cell: (info: any) => {
        const { studentNo } = info.row.original;
        return <span>{studentNo || '-'}</span>;
      },
    }),
    columnHelper.accessor((row: any) => 'School Town', {
      id: 'School Town',
      cell: (info: any) => {
        const { schooltown } = info.row.original;
        return <span>{schooltown}</span>;
      },
    }),
    columnHelper.accessor(() => 'University', {
      id: 'University',
      cell: (info: any) => {
        const { university } = info.row.original;
        return <span>{university}</span>;
      },
    }),
    columnHelper.accessor(() => 'Course', {
      id: 'Course',
      cell: (info: any) => {
        const { course } = info.row.original;
        return <span>{course}</span>;
      },
    }),
    columnHelper.accessor(() => 'Graduation Date', {
      id: 'Graduation Date',
      cell: (info: any) => {
        const { courseEndDate } = info.row.original;
        return <span>{courseEndDate}</span>;
      },
    }),
    columnHelper.accessor(() => 'more', {
      id: 'more',
      header: () => '',
      cell: (info: any) => {
        const { id } = info.row.original;
        return (
          <Dropdown label={<img src={ellipsis} alt="ellipsis" />}>
            <Section className="py-4 w-36">
              <Section>
                <Link
                  className="block px-4 py-2 text-gray-400 hover:bg-gray-100 cursor-pointer hover:text-black"
                  to={`/students/details?student_id=${id}`}
                >
                  View
                </Link>
              </Section>
              <Section className="px-4 py-2 text-gray-400 hover:bg-gray-100 cursor-pointer hover:text-black">
                <button className="text-red-300">Delete</button>
              </Section>
            </Section>
          </Dropdown>
        );
      },
    }),
  ];

  return (
    <Section>
      <Section>
        <Paragraph className="text-lg text-gray-600">Users Table</Paragraph>
      </Section>
      <Section className="my-4">
        <Table
          columns={columns}
          data={USERS.studentsList?.data}
          currentPage={USERS.studentsList?.current_page}
          total={USERS.studentsList?.total}
          limit={USERS.studentsList?.per_page}
          setCurrentPage={function (value: SetStateAction<number>): void {
            throw new Error('Function not implemented.');
          }}
          loading={USERS.loadingStudentsList}
        />
      </Section>
    </Section>
  );
};

export default UsersModule;
