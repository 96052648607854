import { pickErrorMessage, useConfig } from '@hooks/useConfig';
import { useMutation, useQuery } from '@tanstack/react-query';
import { handleError } from '@utils/error';
import { AxiosError } from 'axios';
import { axios } from 'lib';
import { toast } from 'react-toastify';

export type LostPassportPayload = {
  title: string;
  description: string;
  user_id: number | string;
  firstname: string;
  lastname: string;
  passportNo: string;
};

export type LostPassportResponse = {
  status: string;
  lostPassport: any;
};

export const usePassport = () => {
  const config = useConfig();
  const fetchLostPassports = async () => {
    try {
      const response = await axios.get('/losts', config);
      return response.data;
    } catch (error: any) {
      return handleError(error);
    }
  };
  const fetchReturnedPassports = async () => {
    try {
      const response = await axios.get('/return-passports', config);
      return response.data;
    } catch (error: any) {
      return handleError(error);
    }
  };

  const { data: passports, isFetching: loadingPassports } = useQuery({
    queryKey: ['lost-passports'],
    queryFn: fetchLostPassports,
    onError: (err: any) => {
      toast.error(err.passports);
    },
  });
  const { data: returnedPassports, isFetching: loadingReturnedPassports } =
    useQuery({
      queryKey: ['returned-passports'],
      queryFn: fetchReturnedPassports,
      onError: (err: any) => {
        toast.error(err.passports);
      },
    });
  return {
    passports,
    loadingPassports,
    returnedPassports,
    loadingReturnedPassports,
  };
};

export const useUserDetail = (id: any) => {
  const config = useConfig();
  const fetchStudentsDetail = async (id: any) => {
    try {
      const response = await axios.get(`/emegency/show/${id}`, config);
      return response.data;
    } catch (error: any) {
      return handleError(error);
    }
  };

  const { data: users, isFetching: loadingUsers } = useQuery({
    queryKey: ['student-detail', id],
    queryFn: () => fetchStudentsDetail(id),
    onError: (err: any) =>
      toast.error(
        typeof err.users === 'string'
          ? err.users
          : 'Something went wrong fetching status list'
      ),
  });

  return [{ users, loadingUsers }];
};

export const usePassportActions = (endpoint: string) => {
  const config = useConfig();

  const {
    mutate: handleAddPassport,
    data,
    isLoading,
  } = useMutation<
    LostPassportResponse,
    AxiosError,
    Partial<LostPassportPayload>
  >(
    async (data: Partial<LostPassportPayload>) =>
      await axios
        .post(endpoint, data, config)
        .then((res) => {
          return res.data;
        })
        .catch((err) => pickErrorMessage(err)),
    {
      onError: async (error: any) => {
        toast.error(handleError(error));
      },
      onSuccess: () => {
        toast.success('Passport Added Successfully');
      },
    }
  );

  return {
    handleAddPassport,
    data,
    isLoading,
  };
};
//
