import { useStudentsDetail } from '@features/Students';
import Details from '@features/Students/components/Details';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

const StudentDetails = () => {
  const [search] = useSearchParams();
  const queryParams = search.get('student_id');

  const [DETAIL] = useStudentsDetail(queryParams);

  const { studentsList } = DETAIL;
  const profile = studentsList?.student;

  return (
    <div>
      <Details profile={profile} />
    </div>
  );
};

export default StudentDetails;
