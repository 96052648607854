import Table from '@components/elements/Table';
import { SetStateAction, useState } from 'react';

import { createColumnHelper } from '@tanstack/react-table';

import Section from '@components/elements/Section';
import Paragraph from '@components/elements/Text/Paragraph';
import StatusBadge from '@components/elements/StatusBadge';
import { useStudentsData } from '@features/Students';
import AddReturnedPassport from './AddReturnedPass';
import { usePassport } from '../api';
import { getDate } from '@utils/formatters';

const ReturnedPassportModule = () => {
  const [STUDENTS] = useStudentsData();
  const columnHelper = createColumnHelper();
  const [addLostModal, setAddLostModal] = useState<boolean>(false);

  const { loadingReturnedPassports, returnedPassports } = usePassport();

  console.log({ returnedPassports });

  const columns = [
    columnHelper.accessor(() => 'num', {
      id: 'num',
      header: () => <span>S/N</span>,
      cell: (info) => {
        const value = info.row.index + 1;
        return <span>{value}</span>;
      },
    }),
    columnHelper.accessor((row: any) => 'firstname', {
      id: 'firstname',
      cell: (info: any) => {
        const { firstname } = info.row.original;
        return <span>{firstname || '-'}</span>;
      },
    }),
    columnHelper.accessor(() => 'lastname', {
      id: 'lastname',
      cell: (info: any) => {
        const { lastname } = info.row.original;
        return <span>{lastname || '-'}</span>;
      },
    }),
    columnHelper.accessor((row: any) => 'Passport No', {
      id: 'Passport No',
      cell: (info: any) => {
        const { passportNo } = info.row.original;
        return <span>{passportNo || '-'}</span>;
      },
    }),
    columnHelper.accessor((row: any) => 'description', {
      id: 'description',
      cell: (info: any) => {
        const { description } = info.row.original;
        return <span>{description || '-'}</span>;
      },
    }),
    columnHelper.accessor((row: any) => 'Date Added', {
      id: 'Date Added',
      cell: (info: any) => {
        const { created_at } = info.row.original;
        return <span>{getDate(created_at)}</span>;
      },
    }),
  ];

  return (
    <Section>
      {addLostModal && (
        <AddReturnedPassport handleClose={() => setAddLostModal(false)} />
      )}
      <Section className="flex items-center justify-between">
        <Paragraph className="text-lg text-gray-600">
          List of Returned Passports
        </Paragraph>
        <Section
          className="text-primary cursor-pointer border border-primary px-4 py-2"
          onClick={() => setAddLostModal(true)}
        >
          &oplus; Add Returned Passport
        </Section>
      </Section>
      <Section className="my-4">
        <Table
          columns={columns}
          data={returnedPassports?.Returnpasss}
          limit={STUDENTS.studentsList?.per_page}
          loading={STUDENTS.loadingStudentsList}
        />
      </Section>
    </Section>
  );
};

export default ReturnedPassportModule;
