import Table from '@components/elements/Table';
import React, { SetStateAction } from 'react';

import { createColumnHelper } from '@tanstack/react-table';
import Dropdown from '@components/elements/Dropdown';
import Section from '@components/elements/Section';
import { ellipsis } from '@assets/icons';
import { Link } from 'react-router-dom';
import Paragraph from '@components/elements/Text/Paragraph';
import { usePoliceClearanceData } from './api';
import StatusBadge from '@components/elements/StatusBadge';
import { truncate } from '@utils/formatters';

const PoliceClearanceModule = () => {
  const [CLEARANCE] = usePoliceClearanceData();
  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor(() => 'fullname', {
      id: 'num',
      header: () => <span>S/N</span>,
      cell: (info) => {
        const value = info.row.index + 1;
        return <span>{value}</span>;
      },
    }),
    columnHelper.accessor((row: any) => 'fullname', {
      id: 'fullname',
      cell: (info: any) => {
        const { fullName } = info.row.original;
        return <span>{truncate(fullName || '-', 14)}</span>;
      },
    }),
    columnHelper.accessor((row: any) => 'fatherName', {
      id: 'fatherName',
      cell: (info: any) => {
        const { fatherName } = info.row.original;
        return <span>{fatherName}</span>;
      },
    }),
    columnHelper.accessor(() => 'nationality', {
      id: 'nationality',
      cell: (info: any) => {
        const { nationality } = info.row.original;
        return <span>{nationality}</span>;
      },
    }),
    columnHelper.accessor(() => 'Date of Birth', {
      id: 'Date of Birth',
      cell: (info: any) => {
        const { dob } = info.row.original;
        return <span>{dob || '-'}</span>;
      },
    }),
    columnHelper.accessor(() => 'Marital Status', {
      id: 'Marital Status',
      cell: (info: any) => {
        const { maritalStatus } = info.row.original;
        return <span>{maritalStatus}</span>;
      },
    }),
    // columnHelper.accessor(() => 'status', {
    //   id: 'status',
    //   cell: (info: any) => {
    //     const { status } = info.row.original;
    //     return <StatusBadge variant={status as number} />;
    //   },
    // }),
    columnHelper.accessor(() => 'more', {
      id: 'more',
      header: () => '',
      cell: (info: any) => {
        const { user_id } = info.row.original;
        return (
          <Dropdown label={<img src={ellipsis} alt="ellipsis" />}>
            <Section className="py-4 w-36">
              <Section className="px-4 py-2 text-gray-400 hover:bg-gray-100 cursor-pointer hover:text-black">
                <Link to={`/students?userId=${user_id}`}>View</Link>
              </Section>
              <Section className="px-4 py-2 text-gray-400 hover:bg-gray-100 cursor-pointer hover:text-black">
                <button className="text-red-300">Delete</button>
              </Section>
            </Section>
          </Dropdown>
        );
      },
    }),
  ];

  return (
    <Section>
      <Section>
        <Paragraph className="text-lg text-gray-600">
          Police Clearances
        </Paragraph>
      </Section>
      <Section className="my-4">
        <Table
          columns={columns}
          data={CLEARANCE.policeClearanceList?.data}
          total={CLEARANCE.policeClearanceList?.total}
          limit={CLEARANCE.policeClearanceList?.per_page}
          setCurrentPage={function (value: SetStateAction<number>): void {
            throw new Error('Function not implemented.');
          }}
          loading={CLEARANCE.loadingClearances}
        />
      </Section>
    </Section>
  );
};

export default PoliceClearanceModule;
