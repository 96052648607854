import Section from '@components/elements/Section';
import SigninForm from './form';

const AdminSignin = () => {
  return (
    <Section className="flex items-center justify-center">
      <Section className="col-span-2 h-full lg:h-screen flex items-center justify-center">
        <SigninForm />
      </Section>
    </Section>
  );
};
export default AdminSignin;
