import { combineReducers, configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import type { Transform, WebStorage } from 'redux-persist';
import { persistReducer } from 'redux-persist';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import session from 'redux-persist/lib/storage/session';
import Stepper from '../slices/stepperSlice';
import Auth from '@features/Auth/Auth.slice';
// import StudentRegSlice from '@features/Student';

interface PersitConfig {
  key: string;
  storage: WebStorage;
  transforms?: [Transform<any, any>];
}

const encryptStore = encryptTransform({
  secretKey: 'jhjkhsjkhk7862893jkhsdkhklsn,',
  onError: (error) => console.error(error),
});

export const persistConfig: PersitConfig = {
  key: 'root',
  storage: session,
  // blacklist: ['extras'],
  transforms: [encryptStore],
};

const rootReducer = combineReducers({
  // Add reducers
  stepper: Stepper.reducer,
  auth: Auth.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const getMiddlewares = (getDefaultMiddlewares: any) => {
  if (process.env.NODE_ENV !== 'development') return getDefaultMiddlewares();
  return [...getDefaultMiddlewares(), logger];
};

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getMiddlewares,
});
