import { dummyPic, logo, menuIcon, notification } from '@assets/icons';
import Paragraph from '@components/elements/Text/Paragraph';
import Avatar from '@components/elements/Avatar';
import { useState } from 'react';
import Section from '@components/elements/Section';
import EditProfile from '../profile';
import { useAuth } from '@features/Auth/hooks/useAuthStore';

const Header = ({ openNav }: any) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const user = useAuth();

  const { firstname, lastname } = user;

  return (
    <nav>
      <section className="flex items-center justify-between bg-white p-8 md:px-10">
        <div className="flex items-center">
          <button onClick={openNav} className="block lg:hidden">
            <img src={menuIcon} alt="menu" />
          </button>
          <div className="ml-5 block lg:hidden">
            <img src={logo} alt="Logo" />
          </div>
        </div>

        <div className="hidden lg:block mr-auto">
          {/* <h2 className="font-semibold md:text-xl text-white capitalize">
            {T(location?.pathname.split('/')[1].replaceAll('_', ' '))}
          </h2> */}
        </div>

        <div
          className="flex items-center relative cursor-pointer"
          onMouseOver={() => setShowModal(true)}
          onMouseLeave={() => setShowModal(false)}
        >
          {showModal && (
            <Section
              className="absolute top-10 right-0 z-[400]"
              data-aos="fade-up"
            >
              <EditProfile />
            </Section>
          )}
          <div>
            <img src={notification} alt="" />
          </div>
          <div className="hidden md:flex items-center ml-6">
            <Avatar image={dummyPic} className="w-10" />
            <div className="ml-3">
              <Paragraph className="text-[#444444]">
                {firstname} {lastname}
              </Paragraph>
            </div>
          </div>
        </div>
      </section>
    </nav>
  );
};

export default Header;
