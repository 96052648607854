import * as Yup from 'yup';

export const signInValidationSchema = Yup.object({
  email: Yup.string()
    .email('Please enter a valid email address')
    .required('Email is required'),
  password: Yup.string().required('Password is required'),
});

export const signUpValidationSchema = Yup.object({
  email: Yup.string()
    .email('Please enter a valid email address')
    .required('Email is required'),
  firstname: Yup.string(),
  lastname: Yup.string(),
  password: Yup.string().required('Password is required'),
  password_confirmation: Yup.string()
    .required('Confirm Password is required')
    .test('match', 'Passwords do not match', function (value) {
      return this.parent.password === value;
    }),
});

export const studentRegValidationSchema = Yup.object({
  firstName: Yup.string().required('Firstname is required'),
  lastName: Yup.string().required('Lastname is required'),
  date_of_birth: Yup.string().required('Date of birth is required'),
  gender: Yup.string().required('Gender is required'),
  state: Yup.string().required('State is required'),
  passport_number: Yup.string().required('Passport number is required'),
  phone: Yup.string()
    .required('Mobile number is required')
    .min(10, 'Mobile number is invalid'),
  address_one: Yup.string().required('Address is required'),
  address_two: Yup.string(),
  city: Yup.string().required('City is required'),
  post_code: Yup.string(),
  ng_address: Yup.string().required('Nigerian Address is required'),
  student_number: Yup.string().required(
    'Students Registration number is required'
  ),
  location: Yup.string().required('Location is required'),
  university: Yup.string().required('University is required'),
  discipline: Yup.string().required('Discipline is required'),
  course: Yup.string().required('Course is required'),
  course_start_date: Yup.string().required('Course Start date is required'),
  course_end_date: Yup.string().required('Course end date is required'),
  other_info: Yup.string(),
  passport_photo: Yup.mixed()
    .nullable()
    .required('')
    .test(
      'FILE_SIZE',
      'Passport file is too big, Reduce the file and re-upload',
      (value) => !value || (value && (value as File).size <= 1024 * 1024)
    ),
  ng_passport: Yup.mixed()
    .nullable()
    .required('')
    .test(
      'FILE_SIZE',
      'File is too big, Reduce the file and re-upload',
      (value) => !value || (value && (value as File).size <= 1024 * 1024)
    ),
  uk_visa: Yup.mixed()
    .nullable()
    .required('')
    .test(
      'FILE_SIZE',
      'File is too big, Reduce the file and re-upload',
      (value) => !value || (value && (value as File).size <= 1024 * 1024)
    ),
  letter_of_admission: Yup.mixed()
    .nullable()
    .required('')
    .test(
      'FILE_SIZE',
      'File is too big, Reduce the file and re-upload',
      (value) => !value || (value && (value as File).size <= 1024 * 1024)
    ),
  letter_of_acceptance: Yup.mixed()
    .nullable()
    .required('')
    .test(
      'FILE_SIZE',
      'File is too big, Reduce the file and re-upload',
      (value) => !value || (value && (value as File).size <= 1024 * 1024)
    ),
  photocopies_certs: Yup.mixed()
    .nullable()
    .required('')
    .test(
      'FILE_SIZE',
      'File is too big, Reduce the file and re-upload',
      (value) => !value || (value && (value as File).size <= 1024 * 1024)
    ),
  nysc_discharge_certs: Yup.mixed()
    .nullable()
    .required('NYSC Discharge Certificate is required')
    .test(
      'FILE_SIZE',
      'File is too big, Reduce the file and re-upload',
      (value) => !value || (value && (value as File).size <= 1024 * 1024)
    ),
  accept_terms: Yup.boolean().test(
    'is-true',
    'You must agree to the terms and conditions',
    (value) => value === true
  ),
});

export const personalInfoSchema = Yup.object().shape({
  firstName: Yup.string().required('Firstname is required'),
  lastName: Yup.string().required('Lastname is required'),
  date_of_birth: Yup.string().required('Date of birth is required'),
  gender: Yup.string().required('Gender is required'),
  state: Yup.string().required('State is required'),
  passport_number: Yup.string().required('Passport number is required'),
  phone: Yup.string()
    .required('Mobile number is required')
    .min(10, 'Mobile number is invalid'),
  address_one: Yup.string().required('Address is required'),
  address_two: Yup.string(),
  city: Yup.string().required('City is required'),
  post_code: Yup.string(),
  ng_address: Yup.string().required('Nigerian Address is required'),
});

export const aboutUniSchema = Yup.object().shape({
  studentNo: Yup.string().required('Students Registration number is required'),
  schoollocation: Yup.string().required('Location is required'),
  schooltown: Yup.string().required('Location is required'),
  university: Yup.string().required('University is required'),
  group: Yup.string().required('Students Group is required'),
  postcode: Yup.string().required('Postcode is required'),
  discipline: Yup.string().required('Discipline is required'),
  course: Yup.string().required('Course is required'),
  courseStartDate: Yup.string().required('Course Start date is required'),
  courseEndDate: Yup.string().required('Course end date is required'),
  aboutYou: Yup.string(),
  declare: Yup.boolean().test(
    'is-true',
    'You must agree to the terms and conditions',
    (value) => value === true
  ),
});

export const studentsDocumentUploadSchema = Yup.object().shape({
  passport_photo: Yup.mixed()
    .nullable()
    .required('')
    .test(
      'FILE_SIZE',
      'Passport file is too big, Reduce the file and re-upload',
      (value) => !value || (value && (value as File).size <= 1024 * 1024)
    ),
  ng_passport: Yup.mixed()
    .nullable()
    .required('')
    .test(
      'FILE_SIZE',
      'File is too big, Reduce the file and re-upload',
      (value) => !value || (value && (value as File).size <= 1024 * 1024)
    ),
  uk_visa: Yup.mixed()
    .nullable()
    .required('')
    .test(
      'FILE_SIZE',
      'File is too big, Reduce the file and re-upload',
      (value) => !value || (value && (value as File).size <= 1024 * 1024)
    ),
  letter_of_admission: Yup.mixed()
    .nullable()
    .required('')
    .test(
      'FILE_SIZE',
      'File is too big, Reduce the file and re-upload',
      (value) => !value || (value && (value as File).size <= 1024 * 1024)
    ),
  letter_of_acceptance: Yup.mixed()
    .nullable()
    .required('')
    .test(
      'FILE_SIZE',
      'File is too big, Reduce the file and re-upload',
      (value) => !value || (value && (value as File).size <= 1024 * 1024)
    ),
  photocopies_certs: Yup.mixed()
    .nullable()
    .required('')
    .test(
      'FILE_SIZE',
      'File is too big, Reduce the file and re-upload',
      (value) => !value || (value && (value as File).size <= 1024 * 1024)
    ),
  nysc_discharge_certs: Yup.mixed()
    .nullable()
    .required('NYSC Discharge Certificate is required')
    .test(
      'FILE_SIZE',
      'File is too big, Reduce the file and re-upload',
      (value) => !value || (value && (value as File).size <= 1024 * 1024)
    ),
  accept_terms: Yup.boolean().test(
    'is-true',
    'You must agree to the terms and conditions',
    (value) => value === true
  ),
});
