import { Helmet } from 'react-helmet';

const SignupPage = () => {
  return (
    <>
      <Helmet>
        <title>Signup</title>
        <meta name="csrf-token" content="{{ csrf_token() }}" />
      </Helmet>
    </>
  );
};

export default SignupPage;
