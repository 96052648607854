import { useConfig } from '@hooks/useConfig';
import { useQuery } from '@tanstack/react-query';
import { axios } from 'lib';
import { toast } from 'react-toastify';

export const useETC = () => {
  const config = useConfig();
  const fetchETC = async () => {
    try {
      const response = await axios.get(
        '/admin-dashboard/all-emergency-travel',
        config
      );
      return response.data?.emergencies;
    } catch (error) {
      throw error;
    }
  };

  const { data: etcs, isFetching: loadingEtcs } = useQuery({
    queryKey: ['etc-list'],
    queryFn: fetchETC,
    onError: (err) =>
      toast.error(
        typeof err === 'string'
          ? err
          : 'Something went wrong fetching status list'
      ),
  });

  return [{ etcs, loadingEtcs }];
};

export const useUserDetail = (id: any) => {
  const config = useConfig();
  const fetchStudentsDetail = async (id: any) => {
    try {
      const response = await axios.get(`/emegency/show/${id}`, config);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const { data: users, isFetching: loadingUsers } = useQuery({
    queryKey: ['student-detail', id],
    queryFn: () => fetchStudentsDetail(id),
    onError: (err) =>
      toast.error(
        typeof err === 'string'
          ? err
          : 'Something went wrong fetching status list'
      ),
  });

  return [{ users, loadingUsers }];
};
