import { useConfig } from '@hooks/useConfig';
import { useQuery } from '@tanstack/react-query';
import { axios } from 'lib';
import { toast } from 'react-toastify';

export const usePensionsData = () => {
  const config = useConfig();

  const fetchPensions = async () => {
    try {
      const response = await axios.get(
        '/admin-dashboard/all-pension-attestation',
        config
      );
      return response.data.pensioners;
    } catch (error) {
      throw error;
    }
  };

  const { data: pensionsList, isFetching: loadingPensionsList } = useQuery({
    queryKey: ['police-clearance-list'],
    queryFn: fetchPensions,
    onError: (err) =>
      toast.error(
        typeof err === 'string'
          ? err
          : 'Something went wrong fetching clearance list'
      ),
  });

  return [{ pensionsList, loadingPensionsList }];
};
