export const status: { [key: string]: string } = {
  Pending: 'Pending',
};

export const colorVariants: { [key: string]: string } = {
  Pending: '#f0ad4e',
  1: 'red',
  2: '#12B76A',
  3: '#5bc0de',
  4: '#292b2c',
  5: 'black',
};
