import {
  accountsActive,
  accountsBase,
  callActive,
  callBase,
  dashboardActive,
  dashboardBase,
  etcActive,
  etcBase,
  logo,
  logout,
  pensionActive,
  pensionBase,
  policeActive,
  policeBase,
  studentActive,
  studentBase,
} from '@assets/icons';
import Image from '@components/elements/Image';
import SidebarMenuItem from '@components/elements/Menu/SidebarMenuItem';
import Section from '@components/elements/Section';
import Paragraph from '@components/elements/Text/Paragraph';
import appRoutes from '@components/router/appRoutes';
import { useAuthActions } from '@features/Auth/hooks/useAuthStore';

import { useLocation, useNavigate } from 'react-router-dom';

const SideNav = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setUser } = useAuthActions();

  return (
    <div
      className={`bg-white pl-8 py-8 w-[380px] md:w-72 h-full hidden lg:flex flex-col justify-between fixed overflow-y-auto z-50 transition-all ease-in-out duration-300`}
    >
      <div>
        <img src={logo} alt="light logo" />
      </div>
      <aside className="mt-24">
        <div className="mb-2">
          <SidebarMenuItem
            title="Dashboard"
            active={
              location.pathname === '/' ||
              location.pathname.includes(appRoutes.CONSULAR_SERVICES)
            }
            url="/"
            activeIcon={dashboardActive}
            baseIcon={dashboardBase}
            as={'link'}
          />
        </div>
        <div className="mb-2">
          <SidebarMenuItem
            title="Students"
            active={
              location.pathname === appRoutes.STUDENT ||
              location.pathname.includes(appRoutes.STUDENT_DETAILS)
            }
            url="/students"
            activeIcon={studentActive}
            baseIcon={studentBase}
            as={'link'}
          />
        </div>
        <div className="mb-2">
          <SidebarMenuItem
            title="Police"
            active={location.pathname === appRoutes.POLICE_CLEARANCE}
            url="/police-clearance"
            activeIcon={policeActive}
            baseIcon={policeBase}
            as={'link'}
          />
        </div>
        <div className="mb-2">
          <SidebarMenuItem
            title="Pensions"
            active={location.pathname === appRoutes.ATTESTATION_LETTER}
            url={appRoutes.ATTESTATION_LETTER}
            activeIcon={pensionActive}
            baseIcon={pensionBase}
            as={'link'}
          />
        </div>
        {/* <div className="mb-2">
          <SidebarMenuItem
            title="Payments"
            active={location.pathname === '/payments'}
            url="/payments"
            activeIcon={paymentActive}
            baseIcon={paymentBase}
          />
        </div> */}
        <div className="mb-2">
          <SidebarMenuItem
            title="Users"
            active={location.pathname === appRoutes.USERS}
            url={appRoutes.USERS}
            activeIcon={accountsActive}
            baseIcon={accountsBase}
            as={'link'}
          />
        </div>
        <div className="mb-2">
          <SidebarMenuItem
            title="Etcs"
            active={location.pathname === appRoutes.ETC}
            url={appRoutes.ETC}
            activeIcon={etcActive}
            baseIcon={etcBase}
            as={'link'}
          />
        </div>
        <div className="mb-2">
          <SidebarMenuItem
            title="Lost Passports"
            active={location.pathname === appRoutes.LOST_PASSPORTS}
            url={appRoutes.LOST_PASSPORTS}
            activeIcon={etcActive}
            baseIcon={etcBase}
            as={'link'}
          />
        </div>
        <div className="mb-2">
          <SidebarMenuItem
            title="Returned Pass."
            active={location.pathname === appRoutes.RETURNED_PASSPORTS}
            url={appRoutes.RETURNED_PASSPORTS}
            activeIcon={etcActive}
            baseIcon={etcBase}
            as={'link'}
          />
        </div>
        <div className="mb-2">
          <SidebarMenuItem
            title="Manage IVR Calls"
            active={location.pathname === appRoutes.MANAGE_CALLS}
            url={appRoutes.MANAGE_CALLS}
            activeIcon={callActive}
            baseIcon={callBase}
            as={'a'}
          />
        </div>
      </aside>
      <Section
        className="mt-auto flex items-center cursor-pointer"
        onClick={() => {
          setUser(null);
          sessionStorage.clear();
          return navigate('/signin');
        }}
      >
        <Image src={logout} alt="logout | Nigerian High Commission London UK" />{' '}
        <Paragraph className="ml-2">Logout</Paragraph>
      </Section>
    </div>
  );
};

export default SideNav;
