import { useConfig } from '@hooks/useConfig';
import { useQuery } from '@tanstack/react-query';
import { axios } from 'lib';
import { toast } from 'react-toastify';

export const usePoliceClearanceData = () => {
  const config = useConfig();
  const fetchPoliceClearances = async () => {
    try {
      const response = await axios.get(
        '/admin-dashboard/all-police-clearance',
        config
      );
      return response.data.polices;
    } catch (error) {
      throw error;
    }
  };

  const { data: policeClearanceList, isFetching: loadingClearances } = useQuery(
    {
      queryKey: ['police-clearance-list'],
      queryFn: fetchPoliceClearances,
      onError: (err) =>
        toast.error(
          typeof err === 'string'
            ? err
            : 'Something went wrong fetching clearance list'
        ),
    }
  );

  return [{ policeClearanceList, loadingClearances }];
};
