import Table from '@components/elements/Table';
import { SetStateAction, useState } from 'react';

import { createColumnHelper } from '@tanstack/react-table';

import Section from '@components/elements/Section';
import Paragraph from '@components/elements/Text/Paragraph';
import StatusBadge from '@components/elements/StatusBadge';
import { useStudentsData } from '@features/Students';
import AddReturnedPassport from './AddReturnedPass';
import { usePassport } from '../api';

const LostPassportModule = () => {
  const [STUDENTS] = useStudentsData();
  const columnHelper = createColumnHelper();
  const [addLostModal, setAddLostModal] = useState<boolean>(false);

  const { passports, loadingPassports } = usePassport();

  console.log({ passports });
  const columns = [
    columnHelper.accessor(() => 'num', {
      id: 'num',
      header: () => <span>S/N</span>,
      cell: (info) => {
        const value = info.row.index + 1;
        return <span>{value}</span>;
      },
    }),
    columnHelper.accessor((row: any) => 'firstname', {
      id: 'firstname',
      cell: (info: any) => {
        const { firstname } = info.row.original;
        return <span>{firstname || '-'}</span>;
      },
    }),
    columnHelper.accessor(() => 'lastname', {
      id: 'lastname',
      cell: (info: any) => {
        const { lastname } = info.row.original;
        return <span>{lastname || '-'}</span>;
      },
    }),
    columnHelper.accessor((row: any) => 'Student No', {
      id: 'Student No',
      cell: (info: any) => {
        const { studentNo } = info.row.original;
        return <span>{studentNo || '-'}</span>;
      },
    }),
    columnHelper.accessor((row: any) => 'School Town', {
      id: 'School Town',
      cell: (info: any) => {
        const { schooltown } = info.row.original;
        return <span>{schooltown}</span>;
      },
    }),
    columnHelper.accessor(() => 'University', {
      id: 'University',
      cell: (info: any) => {
        const { university } = info.row.original;
        return <span>{university}</span>;
      },
    }),
    columnHelper.accessor(() => 'Course', {
      id: 'Course',
      cell: (info: any) => {
        const { course } = info.row.original;
        return <span>{course}</span>;
      },
    }),
    columnHelper.accessor(() => 'Graduation Date', {
      id: 'Graduation Date',
      cell: (info: any) => {
        const { courseEndDate } = info.row.original;
        return <span>{courseEndDate}</span>;
      },
    }),
    columnHelper.accessor(() => 'status', {
      id: 'status',
      cell: (info: any) => {
        const { status } = info.row.original;
        return <StatusBadge variant={status as number} />;
      },
    }),
  ];

  return (
    <Section>
      {addLostModal && (
        <AddReturnedPassport handleClose={() => setAddLostModal(false)} />
      )}
      <Section className="flex items-center justify-between">
        <Paragraph className="text-lg text-gray-600">
          List of Lost Passports
        </Paragraph>
        <Section
          className="text-primary cursor-pointer border border-primary px-4 py-2"
          onClick={() => setAddLostModal(true)}
        >
          &oplus; Add Lost Passport
        </Section>
      </Section>
      <Section className="my-4">
        <Table
          columns={columns}
          data={STUDENTS.studentsList?.data}
          currentPage={STUDENTS.studentsList?.current_page}
          total={STUDENTS.studentsList?.total}
          limit={STUDENTS.studentsList?.per_page}
          setCurrentPage={function (value: SetStateAction<number>): void {
            throw new Error('Function not implemented.');
          }}
          loading={STUDENTS.loadingStudentsList}
        />
      </Section>
    </Section>
  );
};

export default LostPassportModule;
