import Table from '@components/elements/Table';
import { SetStateAction } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import Section from '@components/elements/Section';
import Paragraph from '@components/elements/Text/Paragraph';
import StatusBadge from '@components/elements/StatusBadge';
import { usePensionsData } from './api';
import { getDateTime } from '@utils/formatters';

const PensionsModule = () => {
  const [PENSIONS] = usePensionsData();
  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor(() => 'fullname', {
      id: 'num',
      header: () => <span>S/N</span>,
      cell: (info) => {
        const value = info.row.index + 1;
        return <span>{value}</span>;
      },
    }),

    columnHelper.accessor((row: any) => 'title', {
      id: 'title',
      cell: (info: any) => {
        const { title } = info.row.original;
        return <span>{title || '-'}</span>;
      },
    }),

    columnHelper.accessor((row: any) => 'firstname', {
      id: 'firstname',
      cell: (info: any) => {
        const { firstname } = info.row.original;
        return <span>{firstname || '-'}</span>;
      },
    }),
    columnHelper.accessor((row: any) => 'lastname', {
      id: 'lastname',
      cell: (info: any) => {
        const { lastname } = info.row.original;
        return <span>{lastname || '-'}</span>;
      },
    }),

    columnHelper.accessor(() => 'created at', {
      id: 'created at',
      cell: (info: any) => {
        const { created_at } = info.row.original;
        return <span>{getDateTime(created_at)}</span>;
      },
    }),

    // columnHelper.accessor(() => 'status', {
    //   id: 'status',
    //   cell: (info: any) => {
    //     const { status } = info.row.original;
    //     return <StatusBadge variant={status as number} />;
    //   },
    // }),
  ];

  return (
    <Section>
      <Section>
        <Paragraph className="text-lg text-gray-600">
          Pensioners Attestation Letter
        </Paragraph>
      </Section>
      <Section className="my-4">
        <Table
          columns={columns}
          data={PENSIONS?.pensionsList?.data || []}
          total={PENSIONS?.pensionsList?.total}
          limit={PENSIONS.pensionsList?.per_page}
          setCurrentPage={function (value: SetStateAction<number>): void {
            throw new Error('Function not implemented.');
          }}
          loading={PENSIONS.loadingPensionsList}
        />
      </Section>
    </Section>
  );
};

export default PensionsModule;
