const appRoutes = {
  HOME: '/',
  TEST: '/test',
  SIGNUP: '/signup',
  STUDENT: '/students',
  STUDENT_DETAILS: '/students/details',
  CONSULAR_SERVICES: '/consular',
  POLICE_CLEARANCE: '/police-clearance',
  CITIZENSHIP_RENUNCIATION: '/citizenship-renunciation',
  ATTESTATION_LETTER: '/attestation-letter',
  NON_IMPEDIMENT: '/non-impediment',
  ETC: '/emergency-travel-applications',
  PAYMENTS: '/payments',
  ACCOUNTS: '/accounts',
  FAQ: '/faq',
  CONTACT: '/contact',
  ADMIN_LOGIN: '/signin',
  USERS: '/users',
  LOST_PASSPORTS: '/lost-passports',
  RETURNED_PASSPORTS: '/returned-passports',
  MANAGE_CALLS: 'https://my.cloudtalk.io/login',
};

export default appRoutes;
