import Button from '@components/elements/Button';
import Form from '@components/elements/Form';
import FormContainer from '@components/elements/FormContainer';
import Modal from '@components/elements/Modal';
import Section from '@components/elements/Section';
import Paragraph from '@components/elements/Text/Paragraph';
import SubHeading from '@components/elements/Text/SubHeading';
import TextField from '@components/elements/TextField';
import { useFormik } from 'formik';
import { LostPassportPayload, usePassportActions } from '../api';
import { passportValidation } from '@utils/validationSchema/passportsSchema';
import TextArea from '@components/elements/TextArea';

interface AddPassportProps {
  handleClose: () => void;
}

const AddReturnedPassport = ({ handleClose }: AddPassportProps) => {
  const initialValues: LostPassportPayload = {
    user_id: '',
    title: '',
    description: '',
    firstname: '',
    lastname: '',
    passportNo: '',
  };

  const { isLoading, handleAddPassport, data } = usePassportActions(
    'return-passport/create'
  );

  const { handleSubmit, values, handleChange, errors, touched } =
    useFormik<LostPassportPayload>({
      initialValues: initialValues,
      validationSchema: passportValidation,
      onSubmit: async (values: LostPassportPayload) => {
        handleAddPassport(values);
        setTimeout(() => handleClose(), 2000);
      },
    });

  return (
    <Modal handleClose={handleClose} width="w-full lg:w-[500px]">
      <Section>
        <Section className="mb-8">
          <SubHeading
            text="Add Returned Passport"
            className="text-primary mb-1"
          />
          <Paragraph className="text-neutral text-sm font-light mb-6">
            Fill this form to add passport detail
          </Paragraph>
        </Section>
        <Form onSubmit={handleSubmit}>
          <Section className=" my-6">
            <TextField
              placeholder="E.g, 656-XXX-XXX-XXX-XXX"
              label="Passport Number *"
              name="passportNo"
              value={values.passportNo}
              error={!!errors?.passportNo && touched?.passportNo}
              onChange={handleChange}
              message={errors.passportNo}
            />
          </Section>
          <Section className=" my-6">
            <TextField
              placeholder="E.g, Njoku"
              label="FirstName *"
              name="firstname"
              value={values.firstname}
              error={!!errors?.firstname && touched?.firstname}
              onChange={handleChange}
              message={errors.firstname}
            />
          </Section>
          <Section className=" my-6">
            <TextField
              placeholder="E.g Emeka"
              label="Last Name *"
              name="lastname"
              value={values.lastname}
              error={!!errors?.lastname && touched?.lastname}
              onChange={handleChange}
              message={errors.lastname}
            />
          </Section>
          <Section className=" my-6">
            <TextArea
              placeholder="Enter Passport Details"
              label="Description"
              name="description"
              textAreaName="description"
              value={values.description}
              error={!!errors?.description && touched?.description}
              onChange={handleChange}
            />
          </Section>
          <Section className="mt-10">
            <Button
              className="bg-primary text-white lg:w-56 w-full"
              type="submit"
              label="Add"
              loading={isLoading}
            />
          </Section>
        </Form>
      </Section>
    </Modal>
  );
};

export default AddReturnedPassport;
