import {
  dashUser,
  dashboardCardIcon2,
  dashboardCardIcon3,
} from '@assets/icons';
import Section from '@components/elements/Section';
import DashboardStatsCard from '@components/widgets/dashboard-stats-card';
import { useDashboardMetrics } from './api';
import UsersTable from './components/users-table';
import appRoutes from '@components/router/appRoutes';
import Card from '@components/elements/Card';
import Dashcard from './components/dashcard';
import { Link } from 'react-router-dom';
import SubHeading from '@components/elements/Text/SubHeading';
import { useEffect, useMemo, useState } from 'react';
import { services } from '@store/mocks';
import Chart from 'react-apexcharts';

const initChartData = {
  options: {
    chart: {
      id: 'HCMIS-DASHBOARD',
    },
    xaxis: {
      categories: services,
    },
    noData: {},
  },
  series: [
    {
      name: 'service-values',
      data: [0, 0, 0, 0],
    },
  ],
};

const AdminModule = () => {
  const [DASHBOARD] = useDashboardMetrics();
  const { dashboardMetrics, loadingDashboardMetrics } = DASHBOARD;

  const [chartData, setChartData] = useState(initChartData);

  const chartSeries = useMemo(
    () => ({
      name: 'Hcmis Services',
      data: [
        dashboardMetrics?.users?.length,
        dashboardMetrics?.students?.length,
        dashboardMetrics?.polices?.length,
        dashboardMetrics?.impends?.length,
        dashboardMetrics?.pensioners?.length,
        dashboardMetrics?.emergencies?.length,
      ],
    }),
    [dashboardMetrics]
  );

  const users = useMemo(() => {
    return dashboardMetrics?.users?.slice(0, 6);
  }, [dashboardMetrics?.users]);

  useEffect(() => {
    if (!loadingDashboardMetrics) {
      setChartData({ ...chartData, series: [chartSeries] });
    }
  }, [chartData, chartSeries, loadingDashboardMetrics]);

  return (
    <Section>
      {loadingDashboardMetrics ? (
        <article className="mb-10 grid md:grid-cols-2 xl:grid-cols-3 gap-8">
          {[1, 2, 3].map((_, idx) => (
            <Card
              padding="0px"
              className="bg-gray-100 rounded-lg w-full h-32 animate-pulse"
              key={`item-${idx}`}
            >
              {''}
            </Card>
          ))}
        </article>
      ) : (
        <article className="mb-10 grid md:grid-cols-2 xl:grid-cols-3 gap-8">
          <DashboardStatsCard
            title="Users Count"
            value={dashboardMetrics?.users?.length}
            icon={dashUser}
            href={appRoutes.PAYMENTS}
          />
          <DashboardStatsCard
            title="No of Students"
            value={dashboardMetrics?.students?.length}
            icon={dashboardCardIcon2}
            href={appRoutes.STUDENT}
          />
          <DashboardStatsCard
            title="Police Clearance"
            value={dashboardMetrics?.polices?.length}
            icon={dashboardCardIcon3}
            href={appRoutes.POLICE_CLEARANCE}
          />
        </article>
      )}
      <article className="mb-10 grid grid-cols-1 md:grid-cols-12 gap-5">
        <div className="md:col-span-12 xl:col-span-8 rounded-lg bg-white p-10">
          <Chart
            options={chartData?.options}
            series={chartData?.series}
            type="bar"
          />
        </div>
        <Section className="md:col-span-12 xl:col-span-4 bg-white p-10">
          <Section className="flex items-center justify-between">
            <Section>
              <SubHeading className="text-sm" text="Users" />
            </Section>
            <Link
              className="text-success text-sm font-medium"
              to={appRoutes.STUDENT}
            >
              View All
            </Link>
          </Section>
          {loadingDashboardMetrics && !users && (
            <Section className="mt-4">
              {[1, 2, 3, 4, 5, 6].map((_, idx) => (
                <Card
                  padding="0px"
                  className="bg-gray-50 w-full h-16 animate-pulse"
                  key={`item-${idx}`}
                >
                  {''}
                </Card>
              ))}
            </Section>
          )}
          {users?.map((user: any, idx: number) => {
            return (
              <Dashcard
                key={`std-${idx}`}
                title={`${user?.firstname} ${user?.lastname}`}
                desc={user?.email}
              />
            );
          })}
        </Section>
      </article>
      <article>
        <UsersTable
          users={dashboardMetrics?.emergencies?.slice(0, 10)}
          loading={loadingDashboardMetrics}
        />
      </article>
    </Section>
  );
};

export default AdminModule;
