import { ReactNode } from 'react';

export const DetailHeader = ({ title }: { title: string }) => {
  return (
    <div className="flex items-center">
      <div className="uppercase text-xs text-success font-bold">{title}</div>
      <div className="ml-2 bg-success w-20 h-[1px]"></div>
    </div>
  );
};

export const DetailItem = ({
  title,
  value,
}: {
  title: string;
  value: string | ReactNode;
}) => {
  return (
    <div className="mt-5 text-neutral sm:flex text-sm sm:text-base items-center">
      <div className="mr-3 font-semibold sm:w-40 capitalize">{title}</div>
      <div>{value || '--'}</div>
    </div>
  );
};
