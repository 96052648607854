import { detailDummy, dummyPic } from '@assets/icons';
import Image from '@components/elements/Image';
import Section from '@components/elements/Section';
import SubHeading from '@components/elements/Text/SubHeading';
import { DetailHeader, DetailItem } from './DetailsComponents';
import StatusBadge from '@components/elements/StatusBadge';
import moment from 'moment';

const Details = ({ profile }: any) => {
  const fullname = `${profile?.firstname || '-'} ${profile?.lastname || '-'}`;
  return (
    <Section className="pb-10 lg:grid grid-cols-12 gap-x-10 xl:gap-x-20">
      <div className="col-span-5 xl:col-span-4">
        <div className="mb-5">
          <Image src={detailDummy} className="w-full" alt="profile pic" />
        </div>

        <div className="mb-5">
          <div className="flex justify-between">
            <SubHeading
              className="text-neutral font-semibold"
              text={fullname}
            />
            <StatusBadge variant={profile?.status as number} />
          </div>

          <div className="mt-5">
            <DetailHeader title="Personal Info" />

            <DetailItem title="Gender" value={profile?.sex} />
            <DetailItem
              title="Birthday"
              value={moment(profile?.dob).format('MMM DD, YYYY')}
            />
            <DetailItem
              title="State of Origin"
              value={profile?.stateOfOrigin}
            />
            <DetailItem title="Passport No." value={profile?.passportNo} />
          </div>
        </div>
      </div>

      <div className="col-span-7 xl:col-span-8 grid grid-cols-1 gap-10">
        <div>
          <DetailHeader title="About" />

          <div className="mt-5">
            <p className="text-neutral">{profile?.aboutYou || '-'}</p>
          </div>
        </div>

        <div>
          <DetailHeader title="Education" />

          <div className="mt-5">
            <DetailItem title="University" value={profile?.university} />
            <DetailItem title="Course" value={profile?.course} />
            <DetailItem title="Student No." value={profile?.studentNo} />
            <DetailItem title="School Town" value={profile?.schooltown} />
            <DetailItem title="Location" value={profile?.schoollocation} />
          </div>
        </div>

        <div>
          <DetailHeader title="Contact Information" />
          <div className="mt-5">
            <DetailItem title="Phone" value={profile?.phoneNumber} />
            <DetailItem title="E-mail" value={profile?.email} />
            <DetailItem title="Address One" value={profile?.address1} />
            <DetailItem title="Address Two" value={profile?.address2} />
            <DetailItem
              title="Nigeria Address"
              value={profile?.nigeriaAddress}
            />
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Details;
