import Table from '@components/elements/Table';
import { SetStateAction } from 'react';
import { useETC } from './api';
import { createColumnHelper } from '@tanstack/react-table';
import Section from '@components/elements/Section';
import Paragraph from '@components/elements/Text/Paragraph';
import { truncate } from '@utils/formatters';

// firstname: string;
//   lastname: string;
//   id: number;
//   user_id: number;
//   form_id: number;
//   title: string;
//   fullname: string;
//   surname: string;
//   otherName: string;
//   type: string;
//   gender: string;
//   dob: string;
//   pob: string;
//   addressInNigeria: string;
//   stateOfOrigin: string;
//   lga: string;
//   phone: string;
//   email: string;
//   occupation: string;
//   permanentAddress: string;
//   country: string;
//   whereYouReside: string;
//   postcode: string;
//   reasonInUk: string;
//   reasonForRequest: string;
//   passportNo: string;
//   dateOfIssue: string;
//   placeOfIssue: string;
//   kinSurname: string;
//   kinOthername: string;
//   kinGender: string;
//   kinAddress: string;
//   kinStateOfOrigin: string;
//   kinLga: string;
//   kinPhone: string;
//   kinEmail: string;
//   kinOccupation: string;
//   kinCountry: string;
//   kinRelationship: string;
//   kinrefreeName: string;
//   RefreeAddress: string;
//   refreeCity: string;
//   refreePostcode: string;
//   otherone: string;
//   othertwo: string;
//   otherthree: string;
//   created_at: string;
//   updated_at: string;

const EtcModule = () => {
  const [ETC] = useETC();
  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor(() => 'num', {
      id: 'num',
      header: () => <span>S/N</span>,
      cell: (info) => {
        const value = info.row.index + 1;
        return <span>{value}</span>;
      },
    }),
    columnHelper.accessor((row: any) => 'fullname', {
      id: 'fullname',
      cell: (info: any) => {
        const { fullname } = info.row.original;
        return <span>{fullname || '-'}</span>;
      },
    }),
    columnHelper.accessor(() => 'phone', {
      id: 'phone',
      cell: (info: any) => {
        const { phone } = info.row.original;
        return <span>{phone || '-'}</span>;
      },
    }),
    columnHelper.accessor((row: any) => 'occupation', {
      id: 'occupation',
      cell: (info: any) => {
        const { occupation } = info.row.original;
        return <span>{occupation || '-'}</span>;
      },
    }),
    columnHelper.accessor((row: any) => 'Reason In Uk', {
      id: 'Reason In Uk',
      cell: (info: any) => {
        const { reasonInUk } = info.row.original;
        return <span>{truncate(reasonInUk || '-', 14)}</span>;
      },
    }),
    columnHelper.accessor(() => 'Reason For Request', {
      id: 'Reason For Request',
      cell: (info: any) => {
        const { reasonForRequest } = info.row.original;
        return <span>{truncate(reasonForRequest || '-', 14)}</span>;
      },
    }),
    columnHelper.accessor(() => 'date-of-Issue', {
      id: 'date of issue',
      cell: (info: any) => {
        const { dateOfIssue } = info.row.original;
        return <span>{dateOfIssue}</span>;
      },
    }),
  ];

  return (
    <Section>
      <Section>
        <Paragraph className="text-lg text-gray-600">
          Emergency Travel Certificates
        </Paragraph>
      </Section>
      <Section className="my-4">
        <Table
          columns={columns}
          data={ETC.etcs?.data || []}
          currentPage={ETC.etcs?.current_page}
          total={ETC.etcs?.total}
          limit={ETC.etcs?.per_page}
          setCurrentPage={function (value: SetStateAction<number>): void {
            throw new Error('Function not implemented.');
          }}
          loading={ETC.loadingEtcs}
        />
      </Section>
    </Section>
  );
};

export default EtcModule;
