import { dashboardCardIcon } from '@assets/icons';
import Card from '@components/elements/Card';
import Image from '@components/elements/Image';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

interface DashboardStatsCardProps {
  icon?: any;
  title: string;
  value: string | number | ReactNode;
  onClick?: () => void;
  href: string;
}

const DashboardStatsCard: React.FC<DashboardStatsCardProps> = ({
  icon,
  title,
  value,
  onClick,
  href,
}) => {
  return (
    <Card padding="0px" className="bg-white rounded-lg w-full">
      <div className="flex p-5">
        <Image src={icon || dashboardCardIcon} alt={'Docs Icon'} />

        <div className="ml-5">
          <h2 className="mb-1 text-base text-[#666666] font-light">{title}</h2>
          <p className="text-2xl text-[#1A1A1A] font-semibold">{value}</p>
        </div>
      </div>

      <div className="px-5 py-3 flex justify-center bg-gray-100 rounded-b-lg">
        <Link to={href} onClick={onClick} className="text-[#404040]">
          View
        </Link>
      </div>
    </Card>
  );
};

export default DashboardStatsCard;
