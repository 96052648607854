import { edit } from '@assets/icons';
import Avatar from '@components/elements/Avatar';
import Card from '@components/elements/Card';
import Image from '@components/elements/Image';
import Section from '@components/elements/Section';
import Span from '@components/elements/Span';
import Paragraph from '@components/elements/Text/Paragraph';
import SubHeading from '@components/elements/Text/SubHeading';
import { useAuth } from '@features/Auth/hooks/useAuthStore';

const EditProfile = () => {
  const auth = useAuth();

  // houseNo?: string | null;
  // phoneNumber?: string | null;
  // postcode?: string | null;
  // stateOfOrigin?: string | null;
  // forename?: string | null;
  // address1?: string | null;
  // address2?: string | null;
  // nigeriaAddress?: string | null;
  // passportNo?: string | null;
  // dob?: string | null;
  // sex?: string | null;
  // others?: string | null;
  // email: string;
  // email_verified_at: null;
  // created_at?: Date;
  // updated_at?: Date;
  // token: string;

  const {
    firstname,
    lastname,
    email,
    email_verified_at,
    dob,
    phoneNumber,
    address1,
  } = auth;
  return (
    <Card className="lg:w-[450px] rounded-2xl bg-white" padding="p-3">
      <Section className="flex items-center flex-col mb-8">
        <Section>
          <Avatar image={null} className="w-24" />
        </Section>
        <Section>
          <SubHeading
            className="text-primary text-center"
            text={`${firstname} ${lastname}`}
          />
          <Paragraph className="text-center text-neutral-copy-black">
            {address1 ?? '-'}
          </Paragraph>
        </Section>
        <Section className="my-4">
          <Section className="flex items-center px-4 py-2 rounded-lg text-primary bg-primary-lite">
            <Image src={edit} alt="Edit Profile" />
            <Paragraph className="text-sm ml-1">Edit</Paragraph>
          </Section>
        </Section>
        <Section className="flex items-center">
          <Section className="bg-neutral-accorion text-neutral-copy-black px-4 py-2 rounded-lg cursor-pointer">
            ID: <Span className="font-semibold">NHC/UK/23/001</Span>
          </Section>
          <Section className="ml-4 bg-[#FAF1DB] text-[#7C5904] px-4 py-2 rounded-lg cursor-pointer">
            {email_verified_at ? 'Verified' : 'Unverified'}
          </Section>
        </Section>
      </Section>
      <Section className="bg-neutral-accorion px-6 py-4 rounded-lg">
        <Section className="flex item-center justify-between mb-5">
          <Section className="text-neutral-copy-black">NIN No.</Section>
          <Section>{'-'}</Section>
        </Section>
        <Section className="flex item-center justify-between mb-5">
          <Section className="text-neutral-copy-black">Phone No.</Section>
          <Section>{phoneNumber || '-'}</Section>
        </Section>
        <Section className="flex item-center justify-between mb-5">
          <Section className="text-neutral-copy-black">Email</Section>
          <Section>{email || '-'}</Section>
        </Section>
        <Section className="flex item-center justify-between">
          <Section className="text-neutral-copy-black">Date of Birth</Section>
          <Section>{dob || "-"}</Section>
        </Section>
      </Section>
    </Card>
  );
};

export default EditProfile;
